<template>
  <div>
    <v-card
      color="transparent"
      flat
    >
      <!-- <h3 class="font-weight-bold text-center mb-5 title">Choose a plan that is right for you</h3> -->
      <h3 class="font-weight-bold text-center mb-5 title">Sell just 1 or 2 meal plans/month and you're already in front!</h3>

      <v-sheet class="d-flex justify-center gap-15 mb-5 flex-column flex-lg-row flex-xl-row">
        <v-card 
          v-for="plan in orderBy(confPlans, 'order', 'asc')" 
          class="box-shadow"
          max-width="680"
          :key="plan.id"
          width="100%"
          outlined
        >
          <v-card-title class="font-weight-bold justify-center primary--text">
            <h4
            class="title-style"
            >{{ plan.title }}</h4>
          </v-card-title>
          <v-card-text>
              <div class="d-flex flex-wrap flex-column fill-height justify-start">
                <div class="text-h4 font-weight-bold dark--text text-center">
                  ${{ ($store.getters['pricing/price'](plan.subscription).unit_amount/100) | numberFormat }}
                </div>
                
                <div v-if="$store.getters['pricing/price'](plan.subscription).type == 'recurring'" class="text-center">
                  every {{ $store.getters['pricing/price'](plan.subscription).interval }}
                </div>   
                <div v-else class="text-center">
                  one time
                </div>

                <!-- <div class="text-center">{{ plan.downloads | checkIfUnli }} Download credits </div>
                <div class="text-center">{{ plan.meals | checkIfUnli }} Meal plans </div> -->

                <div class="ql-editor description" v-html="plan.description">
                </div>
              </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5" v-if="!active && !user.showSubscriptionEndDate">
            <v-btn
              @click="subscribeToPlan(plan)"
              color="primary"
              depressed
              block
            >Subscribe</v-btn>
          </v-card-actions>
          <v-card-actions class="pb-5 px-5" v-else-if="active && user.showSubscriptionEndDate">
            <v-btn
              @click="subscribeToPlan(plan)"
              color="primary"
              depressed
              block
            >Subscribe</v-btn>
          </v-card-actions>
          <v-card-actions class="pb-5 px-5" v-else>
            <v-btn
              v-if="plan.id == active.plan.id"
              color="info"
              depressed
              block
              disabled
            >You're subscribed to this plan</v-btn>

            <div v-else style="width: 100%">
              <v-btn
                @click="setPlan(plan, true)"
                color="info"
                depressed
                block
                v-if="checkPlanPrice(plan.subscription)"
              >
              Upgrade Plan
              </v-btn>
              <v-btn
                @click="setPlan(plan, false)"
                color="warning"
                depressed
                block
                v-else
              >
              Downgrade Plan
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-sheet>

      <div v-if="!active">
        <h4 class="font-weight-bold text-center mt-3 mb-5 px-5">*To access your free 7-day trial, please book a 30-minute demo with our team to help you get set up and navigate the shortcuts.</h4>
        <div class="text-center">
          <v-btn
            @click="bookDemo"
            depressed
            color="info"
            small
          >
          <v-icon left>mdi-calendar-month</v-icon>
          Book Demo
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="upgradeNotice" no-click-animation max-width="400" persistent>
      <v-card>
        <v-card-title>
          <span class="font-weight-bold">Please Confirm</span>
        </v-card-title>
        <v-card-text>
          You're about to upgrade to {{ planToUpgradeTo?.title }}. . This new subscription will be charged immediately, and reset your billing cycle starting today.
          <div class="mt-5">
            <v-btn
              @click="changePlan(planToUpgradeTo)"
              color="accent"
              class="mr-2"
              depressed
              >Confirm</v-btn
            >

            <v-btn depressed @click="upgradeNotice = false">Cancel</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="downgradeNotice" no-click-animation max-width="400" persistent>
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">Please Confirm</span>
        </v-card-title>
        <v-card-text>
          You're about to downgrade to {{ planToUpgradeTo?.title }}. The new subscription will commence at the start of the next billing cycle.
          <div class="mt-5">
            <v-btn
              @click="changePlan(planToUpgradeTo)"
              color="warning"
              class="mr-2"
              depressed
              >Confirm</v-btn
            >

            <v-btn depressed @click="downgradeNotice = false">Cancel</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { orderBy } from 'lodash'
import pluralize from 'pluralize'
import { mapState, mapActions } from 'vuex'
import '../css/quill.css';
// import moment from 'moment'

export default {
  name: 'Membership',

  props: {
    NoConflict: Boolean,
    active: Object
  },

  data() {
    return {
      orderBy,
      pluralize,
      showPayment: false,
      selectedPlan: null,
      upgradeNotice: false,
      downgradeNotice: false,
      planToUpgradeTo: {},
    }
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
      plans: state => state.pricing.plans,
      prices: state => state.subscriptions.subscriptions,
    }),

    confPlans: function () {
      return this.plans.filter(plan => {
        return this.user && plan.role && plan.role.includes(this.user.role) && plan.active
      })
    },

    NoFreeTrial: function () {
      return this.plans.filter(plan => plan.id == '1qUa3JR761oRbm74IiMj')[0]
    }
  },

  methods: {
    ...mapActions('pricing', [
      'getSubscriptions',
      'subscribe',
      'getPlans'
    ]),

    ...mapActions('subscriptions', [
     'updateSubscription', 
    ]),

    showPaymentDialog(plan) {
      this.selectedPlan = plan
      this.showPayment = true
    },

    subscribeToPlan(plan) {
      // let date = moment.unix(this.user.registeredAt?.seconds)
      this.subscribe(plan)

      // if(date.isAfter('2024-01-12')) {
      //   this.subscribe(plan)
      // } else {
      //   this.subscribe(this.NoFreeTrial)
      // }

    },

    bookDemo() {
      setTimeout(() => {
        window.open('https://outlook.office365.com/owa/calendar/ChrisHughes@scood.co/bookings/s/IVGXqh0ZJUK4VBKCVLENUw2', '_blank')
      })
    },

    checkPlanPrice(subscription) {

      const current = +this.$options.filters.numberFormat(this.$store.getters['pricing/price'](this.active.plan.subscription).unit_amount/100) 

      const sub = +this.$options.filters.numberFormat(this.$store.getters['pricing/price'](subscription).unit_amount/100) 

      return current < sub
    },

    changePlan(plan) {
      let hasUpgraded = this.checkPlanPrice(plan.subscription)
      console.log({ hasUpgraded })
      this.updateSubscription({ sub_id: this.active.id, sub_item_id: this.active.items[0].id, new_price_id: plan.subscription, plan_id: plan.id, hasUpgraded })
    },

    setPlan(plan, upgrade) {
      this.planToUpgradeTo = plan
      if(upgrade) this.upgradeNotice = true
      else this.downgradeNotice = true
    }
  },

  filters: {
    checkIfUnli: function (val) {
      if(val > 0) return val

      return 'Unlimited'
    }
  },

  created() {
    this.getSubscriptions()
    this.getPlans()
  }
}

</script>
<style scoped>
.title-style {
  word-break: break-word;
  text-align: center;
}
/*
.description {
  height: 500px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 0 .5rem;
}
*/

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0, 0.3);
}
</style>
